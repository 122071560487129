import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from "@mui/material";
import { IoIosCheckmarkCircle } from "react-icons/io";

export const plansData = [
    {
        plan: "Free",
        price: "",
        features: {
            aiAssistants: true,
            total: "1",
            llms: "GPT",
            knowledgeBase: "400K characters",
            channels: "Web, WhatsApp, Telegram",
            leadSegmentation: "4 segments",
            inbox: true,
            conversationHistory: true,
            supportTickets: true,
            humanHandOff: true,
            aiConversationSummary: false,
            liveChat: true,
            web: true,
            mobile: true,
            videoChat: false,
            workflowBuilder: true,
            workflows: "Unlimited",
            reports: true,
            basicReports: true,
            advancedReports: false,
            externalData: false,
            coPilot: false,
            knowledgeBaseAI: "",
            summaries: false,
            dataPredictions: false,
            integrations: false,
            totalActive: "",
            team: false,
            rolesPermissions: false,
            supports: true,
            slack: true,
            live: false
        }
    },
    {
        plan: "Pro",
        price: "$49",
        features: {
            aiAssistants: true,
            total: "2",
            llms: "Gemini, GPT, Llama",
            knowledgeBase: "2M characters",
            channels: "Web, WhatsApp, Telegram",
            leadSegmentation: "6 segments",
            inbox: true,
            conversationHistory: true,
            supportTickets: true,
            humanHandOff: true,
            aiConversationSummary: true,
            liveChat: true,
            web: true,
            mobile: true,
            videoChat: false,
            workflowBuilder: true,
            workflows: "Unlimited",
            reports: true,
            basicReports: true,
            advancedReports: true,
            externalData: false,
            coPilot: true,
            knowledgeBaseAI: "400 characters",
            summaries: false,
            dataPredictions: false,
            integrations: true,
            totalActive: 5,
            team: true,
            rolesPermissions: false,
            supports: true,
            slack: true,
            live: false
        }
    },
    {
        plan: "Business",
        price: "$139",
        features: {
            aiAssistants: true,
            total: "Unlimited",
            llms: "Gemini, GPT, Llama",
            knowledgeBase: "10M characters",
            channels: "Web, WhatsApp, Telegram",
            leadSegmentation: "Unlimited segments",
            inbox: true,
            conversationHistory: true,
            supportTickets: true,
            humanHandOff: true,
            aiConversationSummary: true,
            liveChat: true,
            web: true,
            mobile: true,
            videoChat: true,
            workflowBuilder: true,
            workflows: "Unlimited",
            reports: true,
            basicReports: true,
            advancedReports: true,
            externalData: true,
            coPilot: true,
            knowledgeBaseAI: "2M characters",
            summaries: true,
            dataPredictions: true,
            integrations: true,
            totalActive: "Unlimited",
            team: true,
            rolesPermissions: true,
            supports: true,
            slack: true,
            live: true
        }
    }
];

export const featureLabels = [
    { label: "AI agents", key: "aiAssistants", className: "table-cell" },
    { label: "Total", key: "total" },
    { label: "LLMs", key: "llms" },
    { label: "Knowledge Base", key: "knowledgeBase" },
    { label: "Channels", key: "channels" },
    { label: "Lead Qualification", key: "leadSegmentation" },
    { label: "Inbox", key: "inbox", className: "table-cell" },
    { label: "Conversation history", key: "conversationHistory" },
    { label: "Support Tickets", key: "supportTickets" },
    { label: "Human Hand Off", key: "humanHandOff" },
    { label: "AI Conversation Summary", key: "aiConversationSummary" },
    { label: "LiveChat", key: "liveChat", className: "table-cell" },
    { label: "Web", key: "web" },
    { label: "Mobile", key: "mobile" },
    { label: "Video Chat", key: "videoChat" },
    { label: "Workflow builder", key: "workflowBuilder", className: "table-cell" },
    { label: "Workflows", key: "workflows" },
    { label: "Reports", key: "reports", className: "table-cell" },
    { label: "Basic reports", key: "basicReports" },
    { label: "Advanced reports with AI Insights", key: "advancedReports" },
    { label: "Connect any external data source", key: "externalData" },
    { label: "Swift AI Copilot", key: "coPilot", className: "table-cell" },
    { label: "Knowledge base", key: "knowledgeBaseAI" },
    { label: "AI Summaries", key: "summaries" },
    { label: "Data predictions", key: "dataPredictions" },
    { label: "Integrations", key: "integrations", className: "table-cell" },
    { label: "Total active", key: "totalActive" },
    { label: "Team", key: "team", className: "table-cell" },
    { label: "Roles and Permissions", key: "rolesPermissions" },
    { label: "Support", key: "supports", className: "table-cell" },
    { label: "Slack", key: "slack" },
    { label: "Live", key: "live" },
];

const PlansComparisonTable = () => (
    <TableContainer component={Paper} className="comparison-table">
        <Table aria-label="comparison table">
            <TableBody>
                {featureLabels.map((feature) => (
                    <TableRow key={feature.key}>
                        <TableCell className={feature.className}><Typography variant="subtitle1">{feature.label}</Typography></TableCell>
                        {plansData.map((plan, index) => (
                            <TableCell key={index} className={feature.className}>
                                {typeof plan.features[feature.key] === "boolean" ?
                                    (plan.features[feature.key] ? <IoIosCheckmarkCircle /> : "") :
                                    plan.features[feature.key]}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

export default PlansComparisonTable;
