// --- Third Party Libraries ---
import React from "react";
import { Box, Typography } from "@mui/material";

// --- Image Utilities ---
import { awardsImages } from "../../utils/imegeUtils";

// --- Constants ---
import { PRODUCT_HUNT_URL } from "../../utils/constants";

// --- Awards Component ---
const Awards = () => {

    // --- Render Component ---
    return (
        <Box className="awards-section">
            <Typography variant="h3" gutterBottom>
                Product of the week
            </Typography>
            <Box className="awards-container">
                <Typography variant="subtitle1">
                    3rd place — recognized as one of the top products on{" "}
                    <span onClick={() => window.open(PRODUCT_HUNT_URL, "_blank")}>
                        Product Hunt
                    </span>
                </Typography>
                <img
                    src={awardsImages.product_of_the_week}
                    alt="Product of the Week"
                />
            </Box>
        </Box>
    );
};

export default Awards;