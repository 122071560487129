// --- Third Party Libraries ---
import React, { useState } from "react";
import { Box, Typography, Button, Card } from "@mui/material";

// --- Internal Components ---
import Header from "./Header";
import Footer from "./Footer";
import Cards from "../../components/PlansContent/cards";

// --- Constants ---
import { HARDCODED_PLANS, APPLICATION_FORM_URL } from "../../utils/constants";

// --- Pricing Component ---
const Pricing = () => {
    // --- State Hooks ---
    const [plans, setPlans] = useState(HARDCODED_PLANS);

    // --- Render Component ---
    return (
        <>
            <Header />
            <Box className="plans-container">
                <Cards plans={plans} />
                <Card className="promotion-container-card">
                    <Typography variant="h3">
                        Apply For A Special Startup Offer <span>$7 monthly</span> for business plan.
                    </Typography>
                    <Typography variant="subtitle1">
                        Our mission is to help startups to do more for less, swiftly adapt to market dynamics, and better understand their customers.
                    </Typography>
                    <Button href={APPLICATION_FORM_URL} target="_blank" variant="contained">
                        Apply
                    </Button>
                </Card>
            </Box>
            <Footer />
        </>
    );
}

export default Pricing;