// --- Third Party Libraries ---
import React from "react";
import { Typography, Box, Button } from "@mui/material";

// --- Constants ---
import { GET_DEMO_URL } from "../../utils/constants";

// --- GetADemo Component ---
const GetADemo = () => {
  
  // --- Render Component ---
  return (
    <Box className="get-a-demo-section">
      <Typography variant="h3">
        Customers, Agents, and Leaders <br /> Empowered by AI
      </Typography>
      {/* <Typography variant="subtitle1">
        Lorem ipsum dolor sit amet consectetur. Volutpat nec vitae tempus cras hendrerit bibendum mauris ultrices. Convallis ultricies ligula gravida lectus suspendisse metus mi fermentum gravida.
      </Typography> */}
      <Button href={GET_DEMO_URL} target="_blank">Get a demo</Button>
    </Box>
  );
}

export default GetADemo;
