// --- Third Party Libraries ---
import React from "react";
import { Box, Typography, Card, CardMedia, CardContent, Button, Grid, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// --- Image Utilities ---
import { blogImages } from "../../utils/imegeUtils";

// --- Blog Component ---
const Blog = () => {
    // --- State and Hooks ---
    const navigate = useNavigate();
    const { t } = useTranslation();

    // --- Image Mapping ---
    const imageMap = {
        cardHeader1: blogImages.picture_article_1,
        cardHeader2: blogImages.picture_article_2,
        cardHeader3: blogImages.picture_article_3
    };

    // --- Render Component ---
    return (
        <Box className="blog-section">
            <Typography variant="h3" gutterBottom>
                {t("landingHeader.blogSectionTitle")}
            </Typography>
            <Typography variant="subtitle1">
                {t("landingHeader.blogSectionSubitle")}
            </Typography>
                <Button onClick={() => navigate("/blog")} variant="contained">
                    {t("landingHeader.readBlogButton")}
                </Button>
            <Grid container spacing={4}>
                {Object.entries(t("blog.articles", { returnObjects: true })).map(([id, article]) => (
                    <Grid item xs={12} sm={6} md={4} key={id}>
                        <Card className="blog-card" onClick={() => navigate(`/blog/${id}`)}>
                            <CardMedia
                                component="img"
                                src={imageMap[`cardHeader${id}`]}
                                alt={article.title}
                            />
                            <CardContent className="blog-content">
                            <Chip label="ARTICLE" />
                                <Typography variant="subtitle1">
                                    {article.title}
                                </Typography>
                                <Typography variant="body1">
                                    {article.subtitle}
                                </Typography>
                                <Typography variant="caption">
                                    {article.date}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Blog;
