import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQs() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Stack direction="column" specing={2} className='faqs-section'>
        <Typography variant="h3" gutterBottom sx={{textAlign: "center"}}>
            Frequently asked questions
        </Typography>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>What is customer service automation software?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Customer service automation software uses AI and machine learning to streamline support processes, handle routine inquiries, and assist human customer service agents in providing faster, more efficient customer service.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>How does IntelSwift improve customer satisfaction?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                By automating routine tasks, providing quick responses, and empowering agents with AI-assisted insights, IntelSwift helps businesses deliver more personalized and efficient customer service, leading to higher satisfaction rates.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Can IntelSwift integrate with our existing systems?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Yes, IntelSwift is designed to seamlessly integrate with popular CRM systems and support tools, ensuring a smooth transition and enhanced functionality for your existing workflows.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography>What are the benefits of automated customer service?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                The benefits of automating your customer service include cost reduction, 24/7 support, and fewer human errors.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
            <Typography>What are the drawbacks of automating customer service?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                The drawbacks of automating customer service mostly revolve around the fact that not all issues can be solved with AI customer support, and some users prefer a human agent. However, you can always include a “contact a human agent” option in your pipeline to address these issues.            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
            <Typography>How to automate your customer service?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                To automate your customer service, you need to decide which tasks and processes can be streamlined by automation. Then choose a customer service automation software like IntelSwift to create your workflows and AI agents.            </Typography>
            </AccordionDetails>
        </Accordion>
    </Stack>
  );
}