// --- Third Party Libraries ---
import React, { useState, useEffect } from "react";
import { Typography, Box, Tabs, Tab, Grid, Chip, useMediaQuery, Card } from "@mui/material";

// --- Image Utilities ---
import { benefitsImages } from "../../utils/imegeUtils";

// --- Benefits Component ---
const Benefits = () => {
    // --- State Hooks ---
    const [activeTab1, setActiveTab1] = useState(0);
    const [loadedTab1, setLoadedTab1] = useState(0);
    const [activeTab2, setActiveTab2] = useState(0);
    const [loadedTab2, setLoadedTab2] = useState(0);
    const [activeTab3, setActiveTab3] = useState(0);
    const [loadedTab3, setLoadedTab3] = useState(0);
    const isSmallScreen = useMediaQuery("(max-width:899px)");

    // --- Event Handlers ---
    const handleTabChange1 = (event, newValue) => {
        setActiveTab1(newValue);
    };

    const handleTabChange2 = (event, newValue) => {
        setActiveTab2(newValue);
    };

    const handleTabChange3 = (event, newValue) => {
        setActiveTab3(newValue);
    };

    // --- useEffect Hooks ---
    useEffect(() => {
        setLoadedTab1(activeTab1);
    }, [activeTab1]);

    useEffect(() => {
        setLoadedTab2(activeTab2);
    }, [activeTab2]);

    useEffect(() => {
        setLoadedTab3(activeTab3);
    }, [activeTab3]);

    // --- Data Arrays ---
    const tabData1 = [
        { label: "AI agents", subtitle: "Provide intelligent, 24/7 support with AI agents that can resolve more than 90% of customer inquiries instantly and accurately.", image: benefitsImages.ai_agents },
        { label: "Omnichannel", subtitle: "Deliver consistent, high-quality experiences across all customer touchpoints, ensuring seamless communication and support through every channel.", image: benefitsImages.omnichannel }
    ];

    const tabData2 = [
        { label: "Tickets", subtitle: "Increase customers loyalty to your brand by resolving tickets quickly, no matter if they were created by AI agent or through your dedicated workflow", image: benefitsImages.tickets },
        // { label: "Broadcast Messaging", subtitle: "Lorem ipsum dolor sit amet consectetur. Volutpat nec vitae tempus cras hendrerit bibendum mauris ultrices. ", image: benefitsImages.broadcast_messaging },
        { label: "Segments", subtitle: "Automate lead qualification by telling AI what are the segments you prefer to categorize your customers.", image: benefitsImages.segments },
        { label: "Swift AI Copilot", subtitle: "Knowledge transfer couldn't be more easier with instant replies, recomendations and more.", image: benefitsImages.swift_copilot }
    ];

    const tabData3 = [
        { label: "AI Insights", subtitle: "Gain valuable insights into your support operations with AI-driven analytics, helping you make informed decisions and optimize performance.", image: benefitsImages.ai_insights },
        { label: "Workflows", subtitle: "Automate repetitive tasks and streamline complex processes with our no code workflows", image: benefitsImages.workflows }
    ];

    // --- Internal Components ---
    const BusinessOutcomes = () => {
        const outcomes = [
            { value: "30%", label: "reduction in operating costs" },
            { value: "90%", label: "issues resolved by AI" },
            { value: "25%", label: "customer satisfaction boost" },
            { value: "10x", label: "conversion vs traditional digital" },
        ];

        return (
            <Box className="outcomes-wrapper">
                <Typography variant="h4" gutterBottom>
                    Real business outcomes
                </Typography>
                <Card className="outcomes-card">
                    <Grid container className="outcomes-grid">
                        {outcomes.map((outcome, index) => (
                            <Grid item xs={12} md={3} key={index} className="outcomes-grid-item">
                                <Typography variant="h4">
                                    {outcome.value}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {outcome.label}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Card>
            </Box>
        );
    };

    // --- Render Component ---
    return (
        <Box className="benefits-section">
            {/* Benefits 1 Section */}
            <Grid container spacing={6} className="benefits-grid">
                <Grid item xs={12} md={6} className="benefits-tabs-section">
                    <div className="benefits-title">
                        <Chip label="FOR CUSTOMERS" variant="outlined" />
                        <Typography variant="h4">
                            Instant support, unmatched service
                        </Typography>
                    </div>
                    <Tabs
                        className="benefits-tabs-list"
                        value={activeTab1}
                        onChange={handleTabChange1}
                        TabIndicatorProps={{ style: { display: "none" } }}
                        orientation="vertical"
                    >
                        {tabData1.map((tab, index) => (
                            <Tab
                                key={index}
                                label={
                                    <div>
                                        <Typography variant="h5">{tab.label}</Typography>
                                        {activeTab1 === index && (
                                            <Typography variant="subtitle1">{tab.subtitle}</Typography>
                                        )}
                                    </div>
                                }
                                className={`benefits-tab ${activeTab1 === index ? "active" : ""}`}
                            />
                        ))}
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={6} className="benefits-tab-content">
                    {tabData1.map((tab, index) => (
                        <img
                            key={index}
                            className={`benefits-tab-image ${loadedTab1 === index ? "active" : ""} ${activeTab1 === 0 ? "ai-agents" : ""}`}
                            src={tab.image}
                            alt={tab.label}
                            style={{ display: activeTab1 === index ? "block" : "none", borderRadius: activeTab1 === 0 ? "20px" : "0" }}
                        />
                    ))}
                </Grid>
            </Grid>

            {/* Benefits 2 Section */}
            <Grid container spacing={6} className="benefits-grid">
                {isSmallScreen ? (
                    <>
                        <Grid item xs={12} md={6} className="benefits-tabs-section">
                            <div className="benefits-title">
                                <Chip label="FOR SUPPORT AGENTS" variant="outlined" />
                                <Typography variant="h4">
                                    Empower your support team with the tools they need
                                </Typography>
                            </div>
                            <Tabs
                                className="benefits-tabs-list"
                                value={activeTab2}
                                onChange={handleTabChange2}
                                TabIndicatorProps={{ style: { display: "none" } }}
                                orientation="vertical"
                            >
                                {tabData2.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        label={
                                            <div>
                                                <Typography variant="h5">{tab.label}</Typography>
                                                {activeTab2 === index && (
                                                    <Typography variant="subtitle1">{tab.subtitle}</Typography>
                                                )}
                                            </div>
                                        }
                                        className={`benefits-tab ${activeTab2 === index ? "active" : ""}`}
                                    />
                                ))}
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} md={6} className="benefits-tab-content">
                            {tabData2.map((tab, index) => (
                                <img
                                    key={index}
                                    className={`benefits-tab-image ${loadedTab2 === index ? "active" : ""}`}
                                    src={tab.image}
                                    alt={tab.label}
                                    style={{ display: activeTab2 === index ? "block" : "none" }}
                                />
                            ))}
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} md={6} className="benefits-tab-content">
                            {tabData2.map((tab, index) => (
                                <img
                                    key={index}
                                    className={`benefits-tab-image ${loadedTab2 === index ? "active" : ""}`}
                                    src={tab.image}
                                    alt={tab.label}
                                    style={{ display: activeTab2 === index ? "block" : "none" }}
                                />
                            ))}
                        </Grid>
                        <Grid item xs={12} md={6} className="benefits-tabs-section">
                            <div className="benefits-title">
                                <Chip label="FOR SUPPORT AGENTS" variant="outlined" />
                                <Typography variant="h4">
                                    Empower your support team with the tools they need
                                </Typography>
                            </div>
                            <Tabs
                                className="benefits-tabs-list"
                                value={activeTab2}
                                onChange={handleTabChange2}
                                TabIndicatorProps={{ style: { display: "none" } }}
                                orientation="vertical"
                            >
                                {tabData2.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        label={
                                            <div>
                                                <Typography variant="h5">{tab.label}</Typography>
                                                {activeTab2 === index && (
                                                    <Typography variant="subtitle1">{tab.subtitle}</Typography>
                                                )}
                                            </div>
                                        }
                                        className={`benefits-tab ${activeTab2 === index ? "active" : ""}`}
                                    />
                                ))}
                            </Tabs>
                        </Grid>
                    </>
                )}
            </Grid>

            {/* Benefits 3 Section */}
            <Grid container spacing={6} className="benefits-grid">
                <Grid item xs={12} md={6} className="benefits-tabs-section">
                    <div className="benefits-title">
                        <Chip label="FOR SUPPORT LEADERS" variant="outlined" />
                        <Typography variant="h4">
                            Optimize your support operations with advanced automation
                        </Typography>
                    </div>
                    <Tabs
                        className="benefits-tabs-list"
                        value={activeTab3}
                        onChange={handleTabChange3}
                        TabIndicatorProps={{ style: { display: "none" } }}
                        orientation="vertical"
                    >
                        {tabData3.map((tab, index) => (
                            <Tab
                                key={index}
                                label={
                                    <div>
                                        <Typography variant="h5">{tab.label}</Typography>
                                        {activeTab3 === index && (
                                            <Typography variant="subtitle1">{tab.subtitle}</Typography>
                                        )}
                                    </div>
                                }
                                className={`benefits-tab ${activeTab3 === index ? "active" : ""}`}
                            />
                        ))}
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={6} className="benefits-tab-content">
                    {tabData3.map((tab, index) => (
                        <img
                            key={index}
                            className={`benefits-tab-image ${loadedTab3 === index ? "active" : ""}`}
                            src={tab.image}
                            alt={tab.label}
                            style={{ display: activeTab3 === index ? "block" : "none" }}
                        />
                    ))}
                </Grid>
            </Grid>
            <Box className="outcomes-wrapper">
                <BusinessOutcomes />
            </Box>
        </Box>
    );
}

export default Benefits;
