// --- Third Party Libraries ---
import React, { useEffect } from "react";
import { Box, Grid, Typography, Card, CardMedia, CardContent, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import cardHeader1 from "../../assets/img/picture-article_1.png";
import cardHeader2 from "../../assets/img/picture-article_2.png";
import cardHeader3 from "../../assets/img/picture-article_3.png";
import Header from "./Header";
import Footer from "./Footer";

// --- BlogPage Component ---
export default function BlogPage() {

    // --- State and Hooks ---
    const images = { cardHeader1, cardHeader2, cardHeader3 };
    const navigate = useNavigate();
    const { t } = useTranslation();

    // --- Event Handlers ---
    const handleCardClick = (id) => {
        navigate(`/blog/${id}`);
    };

    // --- useEffect Hooks ---
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // --- Render Component ---
    return (
        <>
            <Header />
            <Box className="blog-section">
                <Typography variant="h3" gutterBottom>{t("blog.blogPageTitle")}</Typography>
                <Grid container spacing={4} >
                    {Object.entries(t("blog.articles", { returnObjects: true })).map(([id, article]) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={id}>
                            <Card className="blog-card" onClick={() => handleCardClick(id)}>
                                <CardMedia
                                    component="img"
                                    src={images[article.cardMedia]}
                                    alt={article.title}
                                />
                                <CardContent className="blog-content">
                                    <Chip label="ARTICLE" />
                                    <Typography variant="subtitle1">
                                        {article.title}
                                    </Typography>
                                    <Typography variant="body1">
                                        {article.subtitle}
                                    </Typography>
                                    <Typography variant="caption">
                                        {article.date}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Footer />
        </>
    )
}