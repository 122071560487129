// --- Third Party Libraries ---
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// --- Internal Components ---
import i18n from "./../i18n";
import LandingPage from "../pages/LandingPage";
import Privacy from "../pages/LandingPage/Privacy";
import Conditions from "../pages/LandingPage/Conditions";
import Pricing from "../pages/LandingPage/Pricing";
import BlogPage from "./../pages/LandingPage/BlogsPage";
import AboutUs from "./../pages/LandingPage/AboutUs";
import BlogOne from "./../components/Blogs/BlogOne.jsx";
import BlogTwo from "./../components/Blogs/BlogTwo.jsx";
import BlogThree from "./../components/Blogs/BlogThree.jsx";

// --- MainRoutes Component ---
const MainRoutes = () => {
  // --- Hooks ---
  const location = useLocation();

  // --- useEffect Hooks ---
  useEffect(() => {
    // Scroll to top when route changes
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get("lang");
    if (langParam && i18n.language !== langParam) {
      i18n.changeLanguage(langParam);
      localStorage.setItem("selectedLanguage", langParam);
    } else {
      const storedLanguage = localStorage.getItem("selectedLanguage") || "en";
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  // --- Render Component ---
  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/about" element={<AboutUs />} />
      <Route exact path="/pricing" element={<Pricing />} />
      <Route exact path="/blog" element={<BlogPage />} />
      <Route exact path="/privacy-policy" element={<Privacy />} />
      <Route exact path="/terms-and-conditions" element={<Conditions />} />
      <Route exact path="/blog/1" element={<BlogOne />} />
      <Route exact path="/blog/2" element={<BlogTwo />} />
      <Route exact path="/blog/3" element={<BlogThree />} />
    </Routes>
  );
};

export default MainRoutes;
