import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid, Stack } from "@mui/material";
import { BarChart } from '@mui/x-charts/BarChart';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import cardHeader1 from "../../assets/img/picture-article_1.png";
import Header from './../../pages/LandingPage/Header';
import Footer from './../../pages/LandingPage/Footer';

import "./index.css";

const BlogOne = () => {
    const { t } = useTranslation();
    const series = [
        { label: 'B2C', name: 'Series 1', data: [32, 44, 55], color: '#2A52BE' },
        { label: 'B2B', name: 'Series 2', data: [30, 41, 53], color: '#007BA7' },
    ];
    const yAxisConfig = [
        {
            valueFormatter: (value) => `${value}%`
        }
    ]


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Header />
            <Box className="breadcrumbs-container">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" href="/blog" className="breadcrumbs-link">
                        {t("blogOne.breadcrumbsLinkBlog")}
                    </Link>
                    <Typography color="text.primary"> {t("blogOne.blogOneTitle")}</Typography>
                </Breadcrumbs>
            </Box>
            <Box className="blog-post-container">

                <Box className="blog-post-title-container">
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Typography className="blog-post-title" variant="h1">
                                {t("blogOne.blogOneTitle")}
                            </Typography>
                            <Typography className="blog-post-subtitle" variant="h2">
                                {t("blogOne.blogOneSubtitle")}
                            </Typography>
                            <Stack spacing={1} direction="column" className="blog-copy-right">
                                <Typography className="blog-post-subtitle" variant="subtitle2">
                                    {t("blogOne.blogPostBy")}
                                </Typography>
                                <Typography className="blog-post-subtitle" variant="subtitle2">
                                    {t("blogOne.blogPostDate")}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <section className='blog-image'>
                                <img src={cardHeader1} />
                            </section>

                        </Grid>
                    </Grid>
                </Box>
                <Box className="blog-content">
                    <Box>
                        <Typography variant="body1" className="blog-post-description">
                            {t("blogOne.firsParagraphtLineOne")} <strong>{t("blogOne.firsParagraphtLineOneStrong")}</strong>. {t("blogOne.firsParagraphtLineTwo")}<strong>{t("blogOne.firsParagraphtLineTwoStrong")}</strong> {t("blogOne.firsParagraphtLineThree")}<a href="https://www.salesforce.com/content/dam/web/en_us/www/documents/commerce-cloud/salesforce-state-of-commerce-third-edition.pdf"> {t("blogOne.firsParagraphtLineLink")}</a> {t("blogOne.firsParagraphtLineFour")} </Typography>
                    </Box>
                    <Box className="blog-post-container-background">
                        <Typography variant="body1" >{t("blogOne.FirstBlogPostContainerBackgroundUl")}</Typography>
                        <ul>
                            <li><strong>{t("blogOne.FirstBlogPostContainerBackgroundLiOneStrong")}</strong> {t("blogOne.FirstBlogPostContainerBackgroundLiOne")}</li>
                            <li><strong>{t("blogOne.FirstBlogPostContainerBackgroundLiTwoStrong")}</strong>. {t("blogOne.FirstBlogPostContainerBackgroundLiTwo")}</li>
                            <li><strong>{t("blogOne.FirstBlogPostContainerBackgroundLiThreeStrong")}</strong> {t("blogOne.FirstBlogPostContainerBackgroundLiThree")}</li>
                        </ul>
                        <Typography variant="body1" >{t("blogOne.FirstBlogPostContainerBackgroundParagraph")} <strong>{t("blogOne.FirstBlogPostContainerBackgroundParagraphStrong")}</strong></Typography>

                    </Box>
                    <Box>
                        <Typography variant="h3" className="title-paragraph">{t("blogOne.secondParagraphTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogOne.secondParagraphtLineOne")}</Typography>

                    </Box>
                    <Box className="charts-container">
                        <Typography variant="body1" className="title-charts">{t("blogOne.chartsTitle")}</Typography>
                        <Typography variant="body2" className="title-paragraph">{t("blogOne.chartsName")}
                        </Typography>
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: ['total 31%', 'total 42%', 'total 54%'] }]}
                            yAxis={yAxisConfig}
                            series={series}
                            width={380}
                            height={300}
                            axisHighlight={{ x: 'none', y: 'none' }}

                        />
                        <Typography className="article-two-table-text" variant="body1">{t("blogOne.chartsSource")}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3" className="title-paragraph">{t("blogOne.thirdParagraphTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogOne.thirdParagraphtLineOne")}</Typography>

                    </Box>
                    <Box className="ol-list-container">
                        <Box >
                            <Typography variant="body1" className="title-charts">{t("blogOne.ulListRowTitle")}t</Typography>
                            <Typography variant="body2" className="ol-list-subtitle">{t("blogOne.ulListRowName")}</Typography>
                            <Typography className="ol-list-row"><span className="number-span">1</span><span className="text-span">{t("blogOne.ulListRowOne")}</span></Typography>
                            <Typography className="ol-list-row"><span className="number-span">2</span><span className="text-span">{t("blogOne.ulListRowTwo")}</span></Typography>
                            <Typography className="ol-list-row"><span className="number-span">3</span><span className="text-span">{t("blogOne.ulListRowThree")}</span></Typography>
                            <Typography className="ol-list-row"><span className="number-span">4</span><span className="text-span">{t("blogOne.ulListRowFour")}</span></Typography>
                            <Typography className="ol-list-row"><span className="number-span">5</span><span className="text-span">{t("blogOne.ulListRowFive")}</span></Typography>
                        </Box>
                        <Typography className="article-two-table-text" variant="body1">{t("blogOne.ulListSource")}
                        </Typography>

                    </Box>
                    <Box className="blog-post-container-background">
                        <Typography variant="body1" >{t("blogOne.secondBlogPostContainerBackgroundUl")}</Typography>
                        <ul>
                            <li><strong>{t("blogOne.secondBlogPostContainerBackgroundLiOneStrong")}</strong> {t("blogOne.secondBlogPostContainerBackgroundLiOne")}</li>
                            <li><strong>{t("blogOne.secondBlogPostContainerBackgroundLiTwoStrong")}</strong>{t("blogOne.secondBlogPostContainerBackgroundLiTwo")}</li>
                            <li><strong>{t("blogOne.secondBlogPostContainerBackgroundLiThreeStrong")}</strong>{t("blogOne.secondBlogPostContainerBackgroundLiThree")}</li>
                            <li><strong>{t("blogOne.secondBlogPostContainerBackgroundLiFourStrong")}</strong>{t("blogOne.secondBlogPostContainerBackgroundLiFour")}</li>
                        </ul>
                    </Box>
                    <Box>
                        <Typography variant="h3" className="title-paragraph">{t("blogOne.fourthParagraphTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogOne.fourthParagraphtLineOne")}</Typography>

                    </Box>
                    <Box className="blog-post-container-background">
                        <Typography variant="body1" >
                            {t("blogOne.thirdBlogPostContainerBackgroundUl")}</Typography>
                        <ul>
                            <li><strong> {t("blogOne.thirdBlogPostContainerBackgroundLiOneStrong")}</strong> {t("blogOne.thirdBlogPostContainerBackgroundLiOne")}</li>
                            <li><strong>{t("blogOne.thirdBlogPostContainerBackgroundLiTwoStrong")}</strong>{t("blogOne.thirdBlogPostContainerBackgroundLiTwo")}</li>
                            <li><strong>{t("blogOne.thirdBlogPostContainerBackgroundLiThreeStrong")}</strong>{t("blogOne.thirdBlogPostContainerBackgroundLiThree")}</li>
                        </ul>
                    </Box>
                    <Box>
                        <Typography variant="h3" className="title-paragraph">{t("blogOne.fifthParagraphTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogOne.fifthParagraphtLineOne")}
                            <br /><br />
                            {t("blogOne.fifthParagraphtLineTwo")}</Typography>
                    </Box>
                    <Box className="blog-post-container-background">
                        <Typography variant="body1" >
                            {t("blogOne.fourthBlogPostContainerBackgroundLiOne")} <strong>{t("blogOne.fourthBlogPostContainerBackgroundLiOneStrong")}
                            </strong>{t("blogOne.fourthBlogPostContainerBackgroundLiTwo")} <strong>{t("blogOne.fourthBlogPostContainerBackgroundLiTwoStrong")}</strong></Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" className="blog-post-description">{t("blogOne.sixthParagraphtLineTwo")}
                            <br /><br />
                            <a href="https://calendly.com/d/cj8h-wsc-8yg/intelswift-demo">{t("blogOne.lastLinkParagraf")}</a> <strong>{t("blogOne.lastStrongParagraf")}</strong> </Typography>
                    </Box>
                </Box>
            </Box>

            <Footer />
        </>
    );
}

export default BlogOne;
