// --- Third Party Libraries ---
import React from "react";

// --- Internal Components ---
import Header from "./Header.jsx";
import Main from "./Main.jsx";
import GetADemo from "./GetADemo.jsx";
import Benefits from "./Benefits.jsx";
import Testimonials from "./Testimonials.jsx";
import TryForFree from "./TryForFree.jsx";
import Awards from "./Awards.jsx";
import Blog from "./Blog.jsx";
import Footer from "./Footer.jsx";
import FAQs from "./FAQs.jsx"
// --- CSS ---
import "./index.css";

// --- LandingPage Component ---
export default function LandingPage() {
  // --- Render Component ---
  return (
    <>
      <Header />
      <Main />
      <GetADemo />
      <Benefits />
      {/* <Testimonials /> */}
      <TryForFree />
      <Blog />
      <FAQs />
      <Awards />
      <Footer />
    </>
  );
}
