// --- Header Component Images ---
import logo from "../assets/img/horizontal-logo-for-black-background.png";

// --- Main Component Images ---
import inbox from "../assets/img/inbox.png";
import co_pilot from "../assets/img/co_pilot.png";
import ai_agents from "../assets/img/ai_agents.png";
import tickets from "../assets/img/tickets.png";
import omnichannel_1 from "../assets/img/omnichannel_1.png";
import omnichannel_2 from "../assets/img/omnichannel_2.png";
import omnichannel_3 from "../assets/img/omnichannel_3.png";
import omnichannel_icon_1 from "../assets/img/omnichannel_icon_1.png";
import omnichannel_icon_2 from "../assets/img/omnichannel_icon_2.png";
import omnichannel_icon_3 from "../assets/img/omnichannel_icon_3.png";
import omnichannel_icon_4 from "../assets/img/omnichannel_icon_4.png";
import omnichannel_icon_5 from "../assets/img/omnichannel_icon_5.png";
import reporting from "../assets/img/reporting.png";
import workflows from "../assets/img/workflows.png";
import integrations from "../assets/img/integrations.png";
import segments from "../assets/img/segments.png";

// --- Benefits Component Images ---
import benefits_ai_agents from "../assets/img/benefits_ai_agents.gif";
import benefits_omnichannel from "../assets/img/benefits_omnichannel.png";
import benefits_tickets from "../assets/img/benefits_tickets.png";
import benefits_broadcast_messaging from "../assets/img/benefits_broadcast_messaging.png";
import benefits_segments from "../assets/img/benefits_segments.png";
import benefits_swift_copilot from "../assets/img/benefits_swift_copilot.png";
import benefits_ai_insights from "../assets/img/benefits_ai_insights.png";
import benefits_workflows from "../assets/img/benefits_workflows.png";

// --- Testimonials Component Images ---
import apple_logo from "../assets/img/apple_logo.svg";
import google_logo from "../assets/img/google_logo.svg";
import open_ai_logo from "../assets/img/open_ai_logo.svg";
import testimonial_avatar_1 from "../assets/img/testimonial_avatar_1.png";
import testimonial_avatar_2 from "../assets/img/testimonial_avatar_2.png";
import testimonial_avatar_3 from "../assets/img/testimonial_avatar_3.png";

// --- TryForFree Component Images ---
import Logomark from "../assets/img/Logomark.png";

// --- Blog Component Images ---
import picture_article_1 from "./../assets/img/picture-article_1.png";
import picture_article_2 from "./../assets/img/picture-article_2.png";
import picture_article_3 from "./../assets/img/picture-article_3.png";

// --- Awards Component Images ---
import product_of_the_week from "../assets/img/product_of_the_week.png";

// --- Export Images ---
export const headerImages = {
    logo,
};

export const mainImages = {
    inbox,
    co_pilot,
    ai_agents,
    tickets,
    omnichannel: {
        main: [omnichannel_1, omnichannel_2, omnichannel_3],
        icons: [
            omnichannel_icon_1,
            omnichannel_icon_2,
            omnichannel_icon_3,
            omnichannel_icon_4,
            omnichannel_icon_5
        ]
    },
    reporting,
    workflows,
    integrations,
    segments,
};

export const benefitsImages = {
    ai_agents: benefits_ai_agents,
    omnichannel: benefits_omnichannel,
    tickets: benefits_tickets,
    broadcast_messaging: benefits_broadcast_messaging,
    segments: benefits_segments,
    swift_copilot: benefits_swift_copilot,
    ai_insights: benefits_ai_insights,
    workflows: benefits_workflows,
};

export const testimonialsImages = {
    apple_logo,
    google_logo,
    open_ai_logo,
    testimonial_avatar_1,
    testimonial_avatar_2,
    testimonial_avatar_3,
};

export const tryForFreeImages = {
    Logomark,
};

export const blogImages = {
    picture_article_1,
    picture_article_2,
    picture_article_3,
};

export const awardsImages = {
    product_of_the_week,
};